<template>
  <div class="p-4">
    <a-button
      class="float-right mb-3 d-flex align-items-center"
      type="primary"
      @click="setShowAddModelTypeModal(true)"
    >
      <template #icon>
        <i class="bi bi-plus mr-1 mb-1" />
      </template>
      Add Model Type
    </a-button>
    <model-types />
    <add-model-type />
  </div>
</template>
<script>
import ModelTypes from './ModelTypes.vue';
import AddModelType from './AddModelType.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    ModelTypes,
    AddModelType,
  },
  methods: {
    ...mapActions(['setShowAddModelTypeModal']),
  },
};
</script>
